import React from "react";
import MobileStoreButton from "react-mobile-store-button";
import {Button} from "react-bootstrap";

class GetStarted extends React.Component {
    render() {
        return(
            <div className="section7">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <p>{navigator.language || navigator.userLanguage}</p>
                            <p className="title">GET FREE MONTH</p>
                            <p>Download Relevate app and join the most advanced cycling service available today</p>
                            <p style={{margin: "0px", fontStyle: "italic"}}>MONTHLY PLAN</p>
                            <p style={{fontWeight: 900, color: "black"}}>4.99 $ / month</p>
                            <p style={{margin: "0px", fontStyle: "italic"}}>ANNUAL PLAN</p>
                            <p style={{fontWeight: 900, color: "black", paddingBottom: "0px", marginBottom: "0px"}}>2.49 $ / month</p>
                            <p style={{color: "black", paddingTop: "0px", marginTop: "0px"}}>Billed annually at 29.99 per year</p>
                            <p>Try out the full experience risk-free. Before you make a commitment, we offer a <strong style={{fontWeight: 1000, color: "black"}}>1-MONTH Free Trial</strong> of the most comprehensive riding experience.</p>
                            {/*<a href="https://apps.apple.com/si/app/saddle-addict/id1589658144">
                                <Button>Start Yout Free Month</Button>
                            </a>*/}
                            <p style={{marginBottom: "48px"}}>To start your Free Trial:</p>
                            
                            <Button  href="https://apps.apple.com/si/app/saddle-addict/id1589658144" style={{backgroundColor: "red"}} className="buttonAppStoreImg" onclick="return gtag_report_conversion('https://apps.apple.com/si/app/saddle-addict/id1589658144')">
                                <img src="/icons/Download_on_the_App_Store_Badge.svg" style={{margin: "0px", padding: "0px"}} alt="no image" width="200"/>
                            </Button>



                           <p style={{fontSize: "10px", fontStyle: "italic", marginTop: "48px"}}>These are net prices before taxes. Depending on your location, VAT and conversion rates may apply</p> 
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isEuro(){
        const userLocale = navigator.language || navigator.userLanguage; // Get user locale

        const numberFormatter = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency: 'EUR', // Try displaying in Euro
        });
    }
}

export default GetStarted;