import React from "react";
import {createSamplesFromSamplesContainer} from "./activity/Helper";
import queryString from "query-string";
import Button from "react-bootstrap/Button";



class UnsubscribeNews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            validationSuccessful: undefined
        };
        //this.onSegmentSelected = this.onSegmentSelected.bind(this);
    }

    // Vedno ko se ta komponenta inicializira, ugotovimo id zadnjega Activity-a...
    componentDidMount() {
        let search = this.props.location.search;
        const values = queryString.parse(search)
        this.email = values.email;
       // this.unsubscribe(this.email);
    }


    render(){
        return (
            this.state.validationSuccessful ?
            <div>
                 <p style={{marginTop: "48px"}} className="title-small">UNSUBSCRIBED FROM RELEVATE NEWS</p>
                <p className="title-small">{this.showMessage()}</p>
            </div> : 
            <div>
                <p className="title-small">Are you sure you want to unsubscribe from Relevate news?</p>
                <Button onClick={() => this.unsubscribe(this.email)} className="link-button-unsubscribe" style={{ marginBottom: "30px"}}>UNSUBSCRIBE</Button>
            </div>
        );
    }

    showMessage(){
        if (this.state.validationSuccessful !== undefined && this.state.validationSuccessful)
            return "User was successfully unsubscribed from Relevate news!"
        else if (this.state.validationSuccessful !== undefined && !this.state.validationSuccessful)
            return "Unsubscribe user error! Please try again later or contact support."
    }

    unsubscribe(email)
    {
        if(email !== undefined)
        {
            fetch("https://service.saddleaddict.com/unsubscribeMailing?emailAddress="+email,
            {
                method: 'post',
                body: {
                 "emailAddress": email
                }
            })
            .then((response) => {
                if (response.status === 200){
                    this.setState({
                        validationSuccessful: true
                    })
                }
                else{
                    this.setState({
                        validationSuccessful: false
                    })
                }
            }).catch(error => {
                this.setState({
                    validationSuccessful: false
                })
            })
        }
        
    }
}

export default UnsubscribeNews;