import React from "react";

class SAStylisedText extends React.Component {
    render() {
        return(
            <table style={{display: "grid", justifyContent: "center", alignItems: "center"}}>
                <tr style={{fontSize: this.props.fontSize, color: this.props.color}}>
                    <td style={{fontWeight: '700',
                        fontFamily: "Oleo Script",
                        color: "white",
                        fontStyle: "italic",
                        paddingRight: 0,
                        marginRight: 0,
                        paddingBottom: 0}}>R</td>
                    <td style={{fontWeight: '700',
                        fontFamily: "Oleo Script",
                        fontStyle: "italic",
                        paddingLeft: 0,
                        marginLeft: 0}}>elevate</td>
                </tr>
            </table>
        );
    }
}

export default SAStylisedText;