import React from "react";
import appPowerAnalysis from "../images/app/app-power-dark.png";
import img from "../images/final/sensors.jpg"
import device1 from "../images/final/devices-1.jpg";
import device2 from "../images/final/devices-2.jpg";
import Button from "react-bootstrap/Button";
import Card from "../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBolt, faGaugeSimpleHigh, faHeart, faRotate} from "@fortawesome/free-solid-svg-icons";
import Slideshow from "../components/Slideshow";

class DevicesSection extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const images = [device1, device2];
        const centering = ["center", "center"];
        return(
                <div className="section-framed" >
                    <div className="row align-items-center overflow-hidden" style={{backgroundColor: "#0CA9E2"}}>
                        <div className="col-md-5">
                            <p className="title light-color">DEVICES</p>
                            <p className="light-color">Connect to any Bluetooth-enabled sensor, regardless of the vendor. Connecting sensors is easy and intuitive, and you will have to do it only once for each sensor.</p>
                            <p className="light-color">Relevate is smart. It will recognise whether you are going out or spinning indoors and will connect the correct sensor automatically.</p>
                            <p className="light-color">Connecting your smart turbo trainer has never been easier. Apart from Wahoo, Tacx and Elite, we support any FTMS-ready trainer on the market.</p>
                        </div>
                        <div className="col-md-7">
                            <Slideshow images={images} centering={centering} height={450}/>
                        </div>
                    </div>
                </div>
        );
    }
}

export default DevicesSection;