import React from "react";
import { Image} from "react-bootstrap";
import MobileStoreButton from "react-mobile-store-button";
import appHome from "./images/app/app-home-dark.png";
import trenazer from "./images/final/workout-1.jpg";
import appScreen1 from "./images/app/v2/app-screens-v3-67-1_02.jpg"
import appScreen2 from "./images/app/v2/app-screens-v2-67-2_03.jpg"
import appScreen3 from "./images/app/v2/app-screens-v2-67-2_04.jpg"
import appScreen4 from "./images/app/v2/app-screens-v2-67-2_05.jpg"
import appScreen5 from "./images/app/v2/app-screens-v2-67-2_06.jpg"
import appScreen6 from "./images/app/v2/app-screens-v2-67-2_07.jpg"
import appScreen7 from "./images/app/v2/app-screens-v2-67-2_08.jpg"
import appScreen8 from "./images/app/v2/app-screens-v2-67-2_09.jpg"
import appScreen9 from "./images/app/v2/app-screens-v2-67-2_10.jpg"
import appPowerAnalysis from "./images/app/app-power-dark.png";
import appHeatmaps from "./images/app/app-heatmap-dark.png";
import appAchievements from "./images/app/app-achievements-dark.png";
import appSectorDetails from "./images/app/app-sectors-dark.png";
import appStatsAnalysis from "./images/app/app-stats-dark.png";
import appEpicRide from "./images/app/app-epic-ride.png";
import appRide from "./images/app/app-ride.png";
import phoneOnBike from "./images/final/phone-on-bike.jpg";
import phoneOnBikeNarrow from "./images/final/phone-on-bike-narrow.jpg";
import analysis from "./images/final/analysis.jpg";
import analysisSmall from "./images/final/analysis-small.jpg";
import appAnalysis from "./images/app/app-analysis.jpg";
import appAnalysis2 from "./images/app/app-analysis-2.jpg";
import Card from "./components/Card";
import phoneSensor from "./images/final/sensors.jpg";
import smoothscroll from 'smoothscroll-polyfill';
import SAStylisedText from "./SAStylisedText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBolt, faChartLine, faCheckCircle,
    faClockRotateLeft, faGaugeSimpleHigh,
    faHeart, faHeartPulse, faListOl,
    faMedal, faMobilePhone,
    faRotate,
    faSquarePollVertical,
    faTv
} from '@fortawesome/free-solid-svg-icons'
import videoMov from "./videos/app-store-promo.mov";
import videoMp4 from "./videos/app-store-promo.mp4";
import videoSmallMov from "./videos/site-promo-small.mov";
import videoSmallMp4 from "./videos/site-promo-small.mp4"
import SiteSection from "./sections/SiteSection";

import imageRide from "./images/final/ride.jpg";
import DevicesSection from "./sections/DevicesSection";
import HeaderSection from "./sections/HeaderSection";
import Overview from "./activity/Overview";
import OverviewCard from "./components/OverviewCard";
import AnalysisSection from "./sections/AnalysisSection";
import WorkoutSection from "./sections/WorkoutSection";
import EpicRidesSection from "./sections/EpicRidesSection";
import OverviewSection from "./sections/OverviewSection";
import UixSection from "./sections/UixSection";
import ReliveSection from "./sections/ReliveSection";
import SectionsGraph from "./components/SectionsGraph";
import VPMSection from "./sections/VPMSection";

class MainSite extends React.Component {
    constructor(props) {
        super(props);
        this.refRide = React.createRef()
        this.refWorkout = React.createRef()
        this.refAnalyse = React.createRef()
        this.refVpm = React.createRef()
        this.refSectors = React.createRef()
        this.refIntervalTrainings = React.createRef()
        this.refVirtualRides = React.createRef()
        smoothscroll.polyfill();
        this.handleSelect = this.handleSelect.bind(this);
        this.state = {
            innerWidth: null,
            prevScroll: ""
        }
    }

    handleSelect(eventKey) {
        this.props.modeChanged(eventKey);
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentDidUpdate() {
        if (this.state.scroll === this.props.scroll){
            return;
        }
         switch (this.props.scroll){
            case "ride":
                this.refRide.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            case "workout":
                this.refWorkout.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            case "analyse":
                this.refAnalyse.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            case "vpm":
                this.refVpm.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            case "sectors":
                this.refSectors.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
            case "intervalTrainings":
                this.refIntervalTrainings.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                break;
             case "virtualRides":
                 this.refVirtualRides.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                 break;
            default:
                break;
        }

        this.setState({
            scroll: this.props.scroll
        });
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    getImage(){
        if (this.state.innerWidth < 800){
            return <Image fluid src={phoneOnBikeNarrow}/>
        }
        else{
            return <Image fluid src={phoneOnBike}/>
        }
    }

    isPhone(){
        return this.state.innerWidth < 450;
    }

    render() {
        return (
            <div className="App" style={{position: "relative"}}>
                <div>
                    <OverviewSection />
                </div>
                <div className="container" ref={this.refRide}>
                <SiteSection bgImage={imageRide}/>
                </div>
                {/*<div className="section-ride text-body overflow-hidden" ref={this.refRide}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                            </div>
                            <div className="col-md-4">
                                <div className="text-frame rounded-3 shadow-lg animate-left">
                                    <p className="title light-color">RIDE</p>
                                    <p className="light-color">Saddle Addict traces your every move and connects to any Bluetooth sensor you have on your bike. Enjoy all the functionalities of professional riding head-end units combined with a seamless user experience.</p>
                                    <p className="light-color">Next time you go for a ride, your phone is all you will need to take with you to analyse your ride, both on-the-go and once you get back home.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div style={{marginBottom: "0px"}} ref={this.refVpm}>
                    <VPMSection />
                </div>
                {/*<div className="container">
                    <p className="title-section">ROUTE PLANNER & NAVIGATION</p>
                    <div className="row">
                        <div className="col-lg-4">
                            <Card title="POWER PROFILE" color="white" titleColor="white" detailsColor="#D2DAE2" details="Determine your power output capability for any given duration" icon={<FontAwesomeIcon className="fa-2xl" icon={faChartLine} color="#00D7D5" />} />
                        </div>
                        <div className="col-lg-4">
                            <Card title="POWER PROFILE" color="black" titleColor="white" detailsColor="#D2DAE2" details="Determine your power output capability for any given duration" icon={<FontAwesomeIcon className="fa-2xl" icon={faChartLine} color="#00D7D5" />} />
                        </div>
                        <div className="col-lg-4">
                            <Card title="POWER PROFILE" color="black" titleColor="white" detailsColor="#D2DAE2" details="Determine your power output capability for any given duration" icon={<FontAwesomeIcon className="fa-2xl" icon={faChartLine} color="#00D7D5" />} />
                        </div>
                    </div>
                </div>*/}
                <div className="section-sectors overflow-hidden" ref={this.refSectors}>
                    <div>
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                            </div>
                            <div className="col-lg-7">
                                <div className="">
                                    <p className="title light-color">ACTIVE SECTORS</p>
                                    <p className="light-color">Introducing on-the-fly sectors to start instant real-time comparison with your previous efforts with a press on button!</p>
                                    <p className="light-color">Compete with your personal best and most recent effort while recording all the relevant stats along the way. Or analyse custom-made sectors in post-ride analysis</p>
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <div style={{width: "350px", height: "160px"}}>
                                            <SectionsGraph/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={this.refWorkout}>
                    <WorkoutSection />
                </div>
                <div ref={this.refVirtualRides}>
                    <EpicRidesSection/>
                </div>
                <div>{this.getAnalysisSection()}</div>
                {/*<div className="section-user-experience overflow-hidden" ref={this.refInterface}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                                <p className="title">USER EXPERIENCE</p>
                                <p>One app. One screen.</p>
                                <p>Taking advantage of beautiful high-resolution displays of modern mobile devices, Saddle Addict will show you all the important metrics on a single screen.</p>
                                <p>Our user interface is optimised for high-readability with carefully configured layouts. Find all the information you need just by glancing your phone. No swiping, so you can focus on your performance.</p>
                                <p>We also paid special attention to battery usage and made sure you can do as many kilometres as your legs allow.</p>
                            </div>
                            <div className="col-md-7">
                                <Image className="img-fluid animate-right"
                                       width="100%"
                                       src={phoneOnBikeNarrow}
                                       style={{borderRadius: "30px"}}/>
                            </div>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="container" style={{marginTop: "96px"}}>
                    <DevicesSection />
                </div>*/}
                <div style={{backgroundColor: "black", paddingBottom: "48px"}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <Card title="POWER PROFILE" color="black" titleColor="white" detailsColor="#D2DAE2" details="Determine your power output capability for any given duration" icon={<FontAwesomeIcon className="fa-2xl" icon={faChartLine} color="#00D7D5" />} />
                            </div>
                            <div className="col-lg-4">
                                <Card title="LEADERBOARDS" color="black" titleColor="white" detailsColor="#D2DAE2" details="Discover your top-performing activities across different categories" icon={<FontAwesomeIcon className="fa-2xl" icon={faListOl} color="#00D7D5" />} />
                            </div>
                            <div className="col-lg-4">
                                <Card title="ACTIVITY MONITORING" color="black" titleColor="white" detailsColor="#D2DAE2" details="Track key metrics and compare them across different time periods" icon={<FontAwesomeIcon className="fa-2xl" icon={faSquarePollVertical} color="#00D7D5" />} />
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={this.refIntervalTrainings}>
                    <UixSection/>
                </div>
                <div className="section-highlights">
                    <p className="title" style={{marginBottom: "32px"}}>HIGHLIGHTS</p>
                        <div className="horizontal-list">
                            <ul className="highlights">
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen1} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen2} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen3} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen4} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen5} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen6} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen7} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen8} style={{borderRadius: "45px"}}/>
                                </li>
                                <li className="highlights">
                                    <Image className="img-fluid" width="90%" src={appScreen9} style={{borderRadius: "45px"}}/>
                                </li>
                                {/*
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appPowerAnalysis}/>
                                        <p className="title-small">POWER ANALYSIS</p>
                                        <p>Compare your current power curve with your all-time power bests</p>
                                    </table>
                                </li>
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appSectorDetails}/>
                                        <p className="title-small">SECTORS</p>
                                        <p>Compete against yourself through your previous rides</p>
                                    </table>
                                </li>
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appEpicRide}/>
                                        <p className="title-small">EPIC RIDES</p>
                                        <p>Climb your favourite hill and join cycling legends</p>
                                    </table>
                                </li>
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appHeatmaps}/>
                                        <p className="title-small">HEATMAPS</p>
                                        <p>Quickly find that ride you are looking for by picking it on a map</p>
                                    </table>
                                </li>
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appStatsAnalysis}/>
                                        <p className="title-small">ACTIVE DAYS</p>
                                        <p>Graphical analysis of your activity for every month and every year</p>
                                    </table>
                                </li>
                                <li className="highlights">
                                    <table>
                                        <Image className="img-fluid" width="80%" src={appAchievements}/>
                                        <p className="title-small">ACHIEVEMENTS</p>
                                        <p>Find standout moments on your recent ride or workout</p>
                                    </table>
                                </li>
                                */}

                            </ul>
                    </div>
                </div>
                <div ref={this.refPricing}></div>
            </div>
        );
    }


    getAnalysisSection(){
            return(
                <div style={{position: "relative"}}>
                    <div className="section-analysis overflow-hidden" ref={this.refAnalyse}>
                        <div className="container">
                            <div style={{display: "flex", alignItems: "end", justifyContent: "end"}}>
                                <div className="d-none d-lg-block" style={{backgroundColor: "rgba(0, 0, 0, 0.8 )",
                                    width: "600px",
                                    paddingTop: "48px",
                                    paddingBottom: "48px",
                                    borderRadius: "15px",
                                    marginRight: "96px"}}>
                                    <AnalysisSection chartName="chart1"></AnalysisSection>
                                </div>
                                <div style={{marginBottom: this.state.innerWidth > 992 ? "96px" : "0px"}}>
                                    <p className="title-section light-color">ANALYTICS</p>
                                    <p className="light-color">Relevate is all about offering an all-in-one experience to athletes who do outdoor riding as well as indoor workouts. </p>
                                    <p className="light-color">Geek out on your data with our powerful analysis tools, performance evaluation, heatmaps and sectors.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none" style={{backgroundColor: "black", paddingBottom: "48px"}}>
                        <AnalysisSection chartName="chart2"></AnalysisSection>
                    </div>
                </div>
            );
    }
}

export default MainSite;