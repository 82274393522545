import {Image} from "react-bootstrap";
import Card from "../components/Card";
import workout1 from "../images/final/workout-1.jpg";
import workout2 from "../images/final/workout-2.jpg";
import workout3 from "../images/final/workout-3.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCircleCheck,
    faClockRotateLeft,
    faMedal,
    faSquarePollVertical,
    faTv
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Slideshow from "../components/Slideshow";

class WorkoutSection  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            innerWidth: null
        }
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    render(){
        const images = [workout1, workout3, workout2]
        const centering = ["center", "center", "right"]



        if (this.state.innerWidth < 1000){
            return(
                <div className="section-workout-upper text-body overflow-hidden" ref={this.refWorkout}>
                    <p className="title">WORKOUTS</p>
                    <p>No excuses! Roll up your sleeves during the colder months and get ready for riding season! Connect to your smart turbo trainer via Bluetooth and start working out even when the weather is against you.</p>
                    <p>Relevate offers wide variety of training possibilities - from structured workouts to epic climbs we all know and love.</p>
                    {/*<p>Relive your most epic road rides with our Recollections feature and see how your virtual self stacks up against the real you!</p>*/}
                    {/*<Image className="img-fluid" width="100%" style={{paddingTop: 48, paddingBottom: 48}} src={workout1}/>*/}
                    <Slideshow images={images} centering={centering} height={"400px"} />
                    <div className="container" style={{paddingTop: "24px"}}>
                        <div className="row align-items-center">
                            {/*<div className="col-lg-3">
                                <Card title="VIDEO WORKOUTS" details="Spice up your interval training with video" icon={<FontAwesomeIcon className="fa-2xl" icon={faTv} color="#00D7D5" />} />
                            </div>*/}
                            <div className="col-lg-4">
                                <Card title="VIRTUAL RIDES" details="Ride the most epic destinations around the globe!" icon={<FontAwesomeIcon className="fa-2xl" icon={faTv} color="#00D7D5" />} />
                            </div>
                            <div className="col-lg-4">
                                <Card title="INTERVAL TRAININGS" details="Get into the grind with pre-designed or custom interval training programs" icon={<FontAwesomeIcon className="fa-2xl" icon={faSquarePollVertical} color="#00D7D5" />} />
                            </div>
                            <div className="col-lg-4">
                                <Card title="RECOLLECTIONS" details="Simulate your past activities and compare your virtual performance with your real-world efforts" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return(
                <div>
                    <div className="section-workout-upper text-body overflow-hidden"  ref={this.refWorkout}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    {/*<Image className="img-fluid" width="100%" style={{borderRadius: "30px"}} src={workout1}/>*/}
                                    <div style={{clipPath: "inset(0 round 30px)"}}>
                                        <Slideshow images={images} duration={3760} style={{clipPath: "inset(0 round 30px)"}} centering={centering} height={"600px"} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <p className="title">WORKOUTS</p>
                                    <p>No excuses! Roll up your sleeves during the colder months and get ready for riding season! Connect to your smart turbo trainer via Bluetooth and start working out even when the weather is against you.</p>
                                    <p>Relevate offers wide variety of training possibilities - from structured workouts to epic climbs we all know and love.</p>
                                    {/*<p>Relive your most epic road rides with our Recollections feature and see how your virtual self stacks up against the real you!</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-workout-lower text-body overflow-hidden">
                        <div className="container">
                            <div className="row align-items-center">
                                {/*<div className="col-lg-3">
                                    <Card title="VIDEO WORKOUTS" details="Spice up your interval training with video" icon={<FontAwesomeIcon className="fa-2xl" icon={faTv} color="#00D7D5" />} />
                                </div>*/}
                                <div className="col-lg-4">
                                    <Card title="VIRTUAL RIDES" details="Ride the most epic destinations around the globe!" icon={<FontAwesomeIcon className="fa-2xl" icon={faTv} color="#00D7D5" />} />
                                </div>
                                <div className="col-lg-4">
                                    <Card title="INTERVAL TRAININGS" details="Get into the grind with pre-designed or custom interval training programs" icon={<FontAwesomeIcon className="fa-2xl" icon={faSquarePollVertical} color="#00D7D5" />} />
                                </div>
                                <div className="col-lg-4">
                                    <Card title="RECOLLECTIONS" details="Simulate your past activities and compare your virtual performance with your real-world efforts" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default WorkoutSection;