import React, { Component } from 'react';
import './App.css';
import './components/Loader.css';
import 'bootstrap/dist/css/bootstrap.css';
import MainSite from "./MainSite";
import NavigationBar from "./NavigationBar";
import {Button, Image, Modal} from "react-bootstrap";
import logo from './images/logo-v2.png'
import SAStylisedText from "./SAStylisedText";
import GetStarted from "./GetStarted";
import Activity from "./activity/Activity";
import {
  Switch,
  Route,
  BrowserRouter,
  Link
} from "react-router-dom";
import queryString from "query-string";
import ReactGA from "react-ga4";
import AccountValidation from "./AccountValidation";
import UnsubscribeNews from "./UnsubscribeNews";
import HeaderSection from "./sections/HeaderSection";

//ReactGA.initialize("AW-11077181381");//G-NGZYPPM7P8");
//ReactGA.send("pageview");

class App extends Component {
  constructor(props) {
    super(props);
    //const { cookies } = props;
    this.state = {
      supportedModes: [],
      selectedMode: "main",
      token: null,
      prevScrollPosition: 0,
      navbarVisible: true
    };
    this.modeChanged = this.modeChanged.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  // NavigationBar javi, kateri sajt bo aktiven: 0 - main site, 1 - ride, 2 - workout, 3 - analysis
  modeChanged(mode) {
    this.setState({
      selectedMode: mode
    });
  }

  onNameChange(newSharedUserName) {
    this.setState({
      sharedUsername: newSharedUserName
    });
  }

  componentDidMount() {
    //window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll(){
      const prevScrollpos = this.state.prevScrollPosition;
      const currentScrollPos = window.scrollY;
      var visible = true;
      if (prevScrollpos < currentScrollPos && currentScrollPos > 100){
          visible = false;
      }

      this.setState({
        prevScrollPosition: currentScrollPos,
        navbarVisible: visible,
      });
  };

  render() {
    return(
        <div className="App">
          <NavigationBar hide={!this.state.navbarVisible} modeChanged={this.modeChanged}>
          </NavigationBar>
          <HeaderSection />
          <BrowserRouter>
          <Switch>
            <Route path='/sharedActivity' render={(props) => (
                <div>
                  <p style={{marginTop: "48px"}} className="title-small">YOUR FRIEND SHARED THEIR ACTIVITY WITH YOU</p>
                  <Activity {...props} onNameChange={this.onNameChange}/>
                  <p className="title section-break">Powered by Relevate</p>
                  {this.getMode()}
                  <GetStarted />
                </div> )}/>
            <Route path='/unsubscribe' render={(props) => (
                <div >
                   <br></br>
                   <br></br>
                  <UnsubscribeNews {...props} />
                  <br/>
                 
               
                </div> )}/>
            <Route path='/support' /*component={Login }*/ render={(props) => (
                <div className="container" style={{marginBottom: "48px", marginTop: "48px"}}>
                  <p className="title">SUPPORT</p>
                  <p style={{marginBottom: "48px"}}>If you encounter any issue with Relevate mobile application, have any questions regarting its functionality or have any suggestions for improvements, contact us on any of the following ways:</p>
                  <table width="100%" style={{marginBottom: "48px"}}>
                      {/*<tr>
                      <a href="https://www.facebook.com/Saddle-Addict-111436775030852/">FACEBOOK</a>
                    </tr>
                    <tr>
                      <a href="https://www.instagram.com/saddleaddict/">INSTAGRAM</a>
                    </tr>*/}
                    <tr>
                      <a href="mailto:team@relevatefit.com">E-MAIL</a>
                    </tr>
                  </table>
                  <p>Or alternatively, reach us at: </p>
                    <a href="mailto:team@relevatefit.com">team@relevatefit.com</a>
                </div>) }/>
            <Route path='/validateAccount' render={(props) => (
                <div className="container" style={{marginBottom: "192px", marginTop: "192px"}}>
                  <p className="title">ACCOUNT VALIDATION</p>
                  <AccountValidation {...props} onNameChange={this.onNameChange}/>
                </div> )}/>
            <Route path='/' /*component={Login }*/ render={(props) => (
                <div>
                  {this.getMode()}
                  <GetStarted />
                </div>) }/>
          </Switch>
          </BrowserRouter>
          <footer>
            <div className="footer">
              <div className="container">
                <div className="row">
                  <div className="col-sm-3" style={{alignItems: "center", justifyContent: "center"}}>
                    <Image className="img-fluid" src={logo} width="128px" style={{marginTop: "48px"}}/>
                       <SAStylisedText width="100%" fontSize="40px" color="white" />
                  </div>
                  <div className="col-sm-3">
                    <table width="100%">
                      <tr>
                        <p className="title-footer">FEATURES</p>
                      </tr>
                      <tr>
                        <Button className="link" onClick={() => this.modeChanged("ride")}>BIKE COMPUTER</Button>
                      </tr>
                        <tr>
                            <Button className="link" onClick={() => this.modeChanged("vpm")}>VIRTUAL POWER METER</Button>
                        </tr>
                        <tr>
                            <Button className="link" onClick={() => this.modeChanged("sectors")}>ACTIVE SECTORS</Button>
                        </tr>
                      <tr>
                        <Button className="link" onClick={() => this.modeChanged("workout")}>WORKOUTS</Button>
                      </tr>
                        <tr>
                            <Button className="link" onClick={() => this.modeChanged("virtualRides")}>VIRTUAL RIDES</Button>
                        </tr>
                        <tr>
                            <Button className="link" onClick={() => this.modeChanged("intervalTrainings")}>INTERVAL TRAININGS</Button>
                        </tr>
                      <tr>
                        <Button className="link" onClick={() => this.modeChanged("analyse")}>ANALYTICS</Button>
                      </tr>
                    </table>
                  </div>
                  <div className="col-sm-3">
                    <table width="100%">
                      <tr>
                        <p className="title-footer">LEGAL</p>
                      </tr>
                      <tr>
                        <a href="/Policy.html">
                          <Button className="link" go-click="/Policy.html"> PRIVACY POLICY</Button>
                        </a>
                        
                      </tr>
                    </table>
                  </div>
                  <div className="col-sm-3">
                    <table width="100%">
                      <tr>
                        <p className="title-footer">KEEP IN TOUCH</p>
                      </tr>
                      {/*<tr>
                        <a className="link" href="https://www.facebook.com/Saddle-Addict-111436775030852/">FACEBOOK</a>
                      </tr>
                      <tr>
                        <a className="link" href="https://www.instagram.com/saddleaddict/">INSTAGRAM</a>
                      </tr>*/}
                      <tr>
                        <a className="link" href="mailto:team@relevatefit.com">E-MAIL</a>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
    );
  }

  parseAthleteNameFromURL(loactionSearch){
    const values = queryString.parse(loactionSearch)
    return values.athlete;
  }

  getMode(){
    switch (this.state.selectedMode) {
      case "main":
        return (
            <MainSite modeChanged={this.modeChanged}/>
        );
      case "ride":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="ride"/>
        );
      case "workout":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="workout"/>
        );
      case "analyse":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="analyse"/>
        );
      case "vpm":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="vpm"/>
        );
      case "sectors":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="sectors"/>
        );
      case "virtualRides":
        return (
            <MainSite modeChanged={this.modeChanged} scroll="virtualRides"/>
        );
        case "intervalTrainings":
            return (
                <MainSite modeChanged={this.modeChanged} scroll="intervalTrainings"/>
            );
      default:
        break;
    }
  }
}

export default App;
