import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Container, Image} from "react-bootstrap";
import logo from "./images/logo-v2.png";
import SAStylisedText from "./SAStylisedText";
import Button from "react-bootstrap/Button";

class NaviagtionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "Administrator"
    };
    this.navItemClicked = this.navItemClicked.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }
  handleSelect(eventKey) {
    this.props.modeChanged(eventKey);
  }

  handleLogout(eventKey) {
    this.props.logout();
  }

  navItemClicked(item) {
    this.props.modeChanged(item);
  }

  render() {
    return (
      <Navbar collapseOnSelect expand="lg" fixed="top" style={{transform: `translateY(${this.props.hide ? "-100" : "0"}%)`, backgroundColor: 'rgba(0,0,0,0.85)'}}>
        <Container>
          <Navbar.Brand href="/#home" onClick={() => this.handleSelect("main")}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Image src={logo} style={{height: "32px", marginRight: "8px", marginLeft: "0px"}}></Image>
              <p className="light-color" style={{padding: 0, margin: 0, fontFamily: "Oleo Script", fontStyle: "italic", fontSize: 26, fontWeight: 700}}>Relevate</p>
              {/*<SAStylisedText width="100%" fontSize="20px" color="white" />*/}
            </div>
          </Navbar.Brand>
          <Button onclick="return gtag_report_conversion('appstore')" href="https://apps.apple.com/si/app/saddle-addict/id1589658144" className="link-button" style={{marginLeft: "0px", marginBottom: "0px"}}>GET FREE MONTH</Button>
          {/*<Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav me-xl-1" onSelect={this.handleSelect}>
              <Nav.Link className="nav-link"  eventKey="ride">RIDE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="workout">WORKOUT</Nav.Link>
              <Nav.Link className="nav-link" eventKey="analyse">ANALYSE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="relive">RELIVE</Nav.Link>
              <Nav.Link className="nav-link" eventKey="sectors">SECTORS</Nav.Link>
              <Nav.Link className="nav-link" eventKey="pricing">PRICING</Nav.Link>
            </Nav>
          </Navbar.Collapse>*/}
        </Container>
      </Navbar>
    );
  }
}

export default NaviagtionBar