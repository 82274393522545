import React from "react";
import Card from "../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBolt,
    faCheckCircle,
    faClockRotateLeft,
    faMedal,
    faPlug,
    faSquarePollVertical,
    faAreaChart
} from "@fortawesome/free-solid-svg-icons";
import {Image} from "react-bootstrap";
import appHome from "../images/app/app-home-dark.png";
import videoMov from "../videos/app-store-nov-2023-1000kbps.mov";
import videoMp4 from "../videos/site-2-264-800kbps.mp4";
import {Button} from "react-bootstrap";

class OverviewSection extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            wideScreen: false,
            innerWidth: 0
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    resize() {
        if (window.innerWidth > 1400){
            this.setState({
                wideScreen: true,
                innerWidth: window.innerWidth
            })
        }
        else {
            this.setState({
                wideScreen: false,
                innerWidth: window.innerWidth
            })
        }
    }

    render() {
        return (
            <div style={{backgroundColor: "black"}}>
                <div className={(this.state.innerWidth < 1200 || this.state.innerWidth > 1680) ? "container": ""}
                     style={{minHeight: "450px",
                        justifyContent: "center",
                        alignItems: "center"}}>
                    <div className="row align-items-center">
                        <div className={this.state.innerWidth > 1400 ? "col-md-9" : "col-md-8"}>
                            <div style={{paddingTop: this.state.innerWidth > 1200 ? "96px" : "24px", paddingBottom: "24px", paddingLeft: (this.state.innerWidth < 1200 || this.state.innerWidth > 1680) ? "0px": "48px"}}>
                                <div className="row align-items-center">
                                    <div className="col-xl-4">
                                        <Card title="COMPLETE CYCLING EXPERIENCE" color="black" titleColor="white" detailsColor="#D2DAE2" details="Ride activity tracking & indoor training. Cutting-edge analytics and performance evaluation." icon={<FontAwesomeIcon className="fa-2xl" icon={faCheckCircle} color="#4bcffa" />} />
                                    </div>
                                    <div className="col-xl-4">
                                        <Card title="RIDE WITH VIRTUAL POWER METER" color="black" titleColor="white" detailsColor="#D2DAE2" details="Get your power output on-the-fly using only the sensors you have on your phone." icon={<FontAwesomeIcon className="fa-2xl" icon={faBolt} color="#4bcffa" />} />
                                    </div>
                                    <div className="col-xl-4">
                                        {/*<Card title="ADVANCED ANALYTICS" details="Highest standards in data analysis and athlete performance evaluation" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />} />*/}
                                        {/*<Card title="CUTTING-EDGE ANALYTICS" details="to optimize your cycling performance and achieve your goals" icon={<FontAwesomeIcon className="fa-2xl" icon={faClockRotateLeft} color="#00D7D5" />}/>*/}
                                        {/*<Card title="CONNECT WITH STRAVA" color="black" titleColor="white" detailsColor="#D2DAE2" details="Import your previous activities and upload your latest efforts to share them with your friends" icon={<FontAwesomeIcon className="fa-2xl" icon={faPlug} color="#00D7D5" />}/>*/}
                                        <Card title="ACTIVE SECTORS" color="black" titleColor="white" detailsColor="#D2DAE2" details="Start comparing with your previous efforts by a touch of a button." icon={<FontAwesomeIcon className="fa-2xl" icon={faMedal} color="#4bcffa" />}/>
                                    </div>
                                </div>
                               
                                <div className="d-none d-md-block">
                                    <p className="light-color" style={{fontStyle: "italic", marginTop: "48px"}}>Upgrade to our product today and take your cycling experience to the next level risk free. Enjoy your Free Month while sharing your new activities to Strava or exporting them to TCX.</p>
                                    <Button  href="https://apps.apple.com/si/app/saddle-addict/id1589658144" style={{color: "red"}} className="buttonAppStoreImg" onclick="return gtag_report_conversion('https://apps.apple.com/si/app/saddle-addict/id1589658144')">
                                        <img src="/icons/Download_on_the_App_Store_Badge.svg"  alt="no image" width="200"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className={this.state.innerWidth > 1400 ? "col-md-3" : "col-md-4"}  style={{position: "relative", zIndex: "999"}}>
                            <div className="d-block d-sm-block d-md-none d-lg-none" style={{height: "120px"}}/>
                            <div style={{height: "300px"}}></div>
                            {/*<Image className="video-absolute-position" src={appHome}/>*/}
                            <video style={{borderWidth: "0px",
                                background: "linear-gradient(to bottom, rgba(12,195,248,1.0) 3%, rgba(109,198,224,1.0) 97%)",
                                borderColor: "#0CA9E2",
                                borderRadius: "45px",
                                borderStyle: "solid",
                                padding: "12px"}} autoPlay loop muted solid playsInline width="100%" max className="video-absolute-position">
                                {/*<source src={videoMov} type="video/quicktime"/>*/}
                                <source src={videoMp4} type="video/mp4"/>
                            </video>
                            <div className="d-block d-sm-block d-md-none d-lg-none" style={{height: "120px"}}/>
                        </div>
                        <div className="d-md-none" style={{marginBottom: "32px"}}>
                            <p className="light-color"  style={{fontStyle: "italic", marginTop: "16px"}}>Upgrade to our product today and take your cycling experience to the next level risk free. Enjoy your Free Month while sharing your new activities to Strava or exporting them to TCX.</p>
                            <Button  href="https://apps.apple.com/si/app/saddle-addict/id1589658144"  style={{color: "red"}} className="buttonAppStoreImg" onclick="return gtag_report_conversion('https://apps.apple.com/si/app/saddle-addict/id1589658144')">
                                <img src="/icons/Download_on_the_App_Store_Badge.svg"  alt="no image" width="200"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default OverviewSection;