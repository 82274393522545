import React from "react";
import Chart from "chart.js";

class ChartEntity {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}

class VPMChart extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            currentTime: new Date(),
            index: 1
        }
    }

    componentDidMount() {
        this.intervalId = setInterval(() => {
            this.setState({
                currentTime: new Date(),
                index: this.state.index + 1
            });
        }, 100);

        //const displayedData = this.props.powers.slice(0, this.state.index % this.props.powers.length)
        //this.populateChart(displayedData)
    }

    render(){
        //const displayedData = this.props.powers.slice(0, this.state.index % this.props.powers.length)
        //this.populateChart(displayedData)
        return(
            <div container>
                <canvas id={"vpm-chart"} height="100%" width="100%"/>
            </div>
        );
    }

    populateChart(profile){
        var ctx = document.getElementById('vpm-chart').getContext('2d');

        let profileData = [];
        let selectedData = [];
        for (let i = 0; i < profile.length; i++) {
            const profilePoint = profile[i];
            profileData.push(new ChartEntity(i, profilePoint));
        }

        for (let i = 5; i < 14; i++) {
            const profilePoint = profile[i];
            selectedData.push(new ChartEntity(i, profilePoint));
        }


        let chart = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    label: 'First dataset',
                    data: profileData,
                    tension: 0.4,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "white",
                    borderWidth: 2.0
                },{
                    label: 'First dataset',
                    data: selectedData,
                    tension: 0.4,
                    backgroundColor: "red",
                    fill: true,
                    pointRadius: 0,
                    borderColor: "white",
                    borderWidth: 2.0
                }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                spanGaps: false,
                legend: {
                    display: false
                },
                animation: false,
                scales: {
                    yAxes: [
                        {
                            max: 160,
                            display: false,
                            ticks: {
                                padding: 0,
                                max: 350,
                                beginAtZero: true
                            }
                        }
                    ],
                    xAxes: [
                        {
                            type: 'linear',
                            display: false,
                            ticks: {
                                padding: 0,
                                max: 139,
                                beginAtZero: true
                            }
                        }
                    ]
                },
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }
}

export default VPMChart