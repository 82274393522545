import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBolt} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const refreshPeriod = 50.0      // na koliko milisekund se posodablja zaslon
const totalDuration = 12500.0   // trajanje posnetka

class VPMWidget extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            currentTime: new Date(),
            index: 0,
            duration: 0.0
        }
    }

    componentDidMount() {
        {/*this.intervalId = setInterval(() => {
            let newDuration = this.state.duration + refreshPeriod;
            if (newDuration >= totalDuration){
                this.setState({
                    currentTime: new Date(),
                    index: 0,
                    duration: 0.0
                });
                return;
            }
            let newIndex = this.state.index + 1;
            let sampleCount = this.props.powers.length;
            let samplePeriod = totalDuration / sampleCount;
            if (newDuration - (this.state.index * samplePeriod) >= samplePeriod){
                this.setState({
                    currentTime: new Date(),
                    index: this.state.index + 1,
                    duration: this.state.duration + refreshPeriod
                });
            }
            else{
                this.setState({
                    currentTime: new Date(),
                    duration: this.state.duration + refreshPeriod
                });
            }
        }, refreshPeriod);*/}
    }
    render(){
        return(
            <div>
                <table className="align-middle" width="100%" height="100%">
                    <tr>
                        <td width="35%" style={{textAlign: "right"}}>
                            <FontAwesomeIcon className="fa-xl" icon={faBolt} color="black" />
                        </td>
                        <td width="65%" style={{textAlign: "left"}}>
                            <p style={{height: "42px",
                                verticalAlign: "middle",
                                fontWeight: "900",
                                fontSize: "28px",
                                marginBottom: "0px",
                                display: "flex",
                                color: "black",
                                alignItems: "center"}}>{this.props.value}</p>
                        </td>
                    </tr>
                </table>
            </div>
        );
    }

    // metoda dela interpolacijo med refreshPeriodom in samplePeriodom
    /*getValue(){
        let sampleCount = this.props.powers.length;
        let samplePeriod = totalDuration / sampleCount;
        let i = this.props.index;

        let nextPower = this.props.powers[i];
        let previousPower = (i > 0) ? this.props.powers[i - 1] : this.props.powers[this.props.powers.length - 1];
        let deltaPower = nextPower - previousPower;

        let nextPowerDuration = (i + 1) * samplePeriod;
        let previousPowerDuration = (i > 0) ? i * samplePeriod : 0;
        let deltaDuration = nextPowerDuration - previousPowerDuration;
        let excessDuration = this.props.duration - previousPowerDuration;
        let excessDurationProportion = excessDuration / deltaDuration;

        let power = deltaPower * excessDurationProportion + previousPower;
        return Math.round(power);
    }*/
}

export default VPMWidget;