import React from "react";
import appPowerAnalysis from "../images/app/app-power-dark.png";
import {Image} from "react-bootstrap";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Button from "react-bootstrap/Button";
import rider1 from "../images/final/rider-1.jpg";
import rider2 from "../images/final/bike-computer-za-site-3.jpg";
import rider3 from "../images/final/rider-3.jpg";
import rider4 from "../images/final/bike-computer-za-site-4.jpg"
import Slideshow from "../components/Slideshow";
import Card from "../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPlug, faServer, faSquareRootVariable, faUpload} from "@fortawesome/free-solid-svg-icons";


class SiteSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()
    }

    render() {
        const images = [rider2, rider1, rider4];
        const centering = ["center", "center", "center"]
        return(
            <div>
                <div className="section-framed">
                    <div className="row align-items-center" style={{backgroundColor: "#0CA9E2"}}>
                        <div className="col-lg-5" style={{paddingTop: "30px", paddingBottom: "8px"}}>
                            <p className="title-section light-color">BIKE COMPUTER</p>
                            <p className="light-color">Relevate traces your every move and connects to any Bluetooth sensor you have on your bike. Enjoy all the functionalities of professional riding head-end units combined with a seamless user experience.</p>
                            <p className="light-color">Next time you go for a ride, your phone is all you will need to take with you to analyse your ride, both on-the-go and once you get back home.</p>
                            <p className="light-color" style={{color: "black", fontStyle: "italic", fontWeight: "900"}}>INCLUDING ROUTE PLANNER, NAVIGATION & ALTITUDE PROFILE</p>
                        </div>
                        <div className="col-lg-7">
                            <Slideshow images={images} centering={centering} duration={3160} height={this.state.innerWidth > 991 ? "500px" : "350px"}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default SiteSection;