import {Image} from "react-bootstrap";

import workout1 from "../images/final/workout-1.jpg";
import workout2 from "../images/final/workout-2.jpg";
import workout3 from "../images/final/workout-3.jpg";
import vpmMovie from "../videos/site-vpm-1436x1836-2kbs-hiexp.mp4";

import React from "react";
import Slideshow from "../components/Slideshow";
import reliveMov from "../videos/sa-relive-264.mov";
import reliveMp4 from "../videos/sa-relive-264.mp4";
import {faBolt, faClockRotateLeft, faSquarePollVertical, faTv} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SectionsGraph from "../components/SectionsGraph";
import VPMChart from "../components/VPMChart";
import VPMWidget from "../components/VPMWidget";
import Card from "../components/Card";

const refreshPeriod = 50.0      // na koliko milisekund se posodablja zaslon
const powers = [240,248,252,280,305,330,335,354,340,235,220,225,245,230,235,290,330,370,350,290,260,240,260,355,405,465,480,490]
class VPMSection  extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = {
            innerWidth: null,
            vpmValue: 0,
            excessPercentage: 0,
            currentTime: 0,
            previousIndex: null
        }
        this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    }

    resize() {
        this.setState({innerWidth: window.innerWidth})
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this))
        this.resize()

        this.intervalId = setInterval(() => {
            let currentTime = this.videoRef.current.currentTime;
            let totalDuration = this.videoRef.current.duration;
            let percentageVideoPlayed = currentTime / totalDuration;

            let previousIndex = 0;
            while (previousIndex <= powers.length - 1){
                if (previousIndex / (powers.length - 1) > percentageVideoPlayed){
                    break;
                }
                previousIndex++;
            }

            let nextIndex = (previousIndex < powers.length - 1) ? previousIndex + 1 : 0;
            let sampleDuration = totalDuration / (powers.length - 1);
            let excessPercentage = currentTime < sampleDuration ? currentTime / sampleDuration : (currentTime - previousIndex * sampleDuration) / sampleDuration;
            let value = (powers[nextIndex] - powers[previousIndex]) * Math.abs(excessPercentage) + powers[previousIndex];
            if (!isNaN(value)){
                this.setState({
                    value: Math.round(value),
                    excessPercentage: excessPercentage,
                    currentTime: currentTime,
                    previousIndex: previousIndex
                });
            }
        }, refreshPeriod);
    }

    handleTimeUpdate(){
        this.setState({
            count: this.state.count + 1
        })
    }

    render(){
        const images = [workout1, workout3, workout2]
        const centering = ["center", "center", "right"]
        //const powers = [240,240,241,242,241,242,243,243,245,247,247,246,246,247,247,248,247,248,248,250,252,254,256,257,259,260,263,264,267,269,270,273,273,272,272,273,275,275,274,275,276,277,272,270,263,254,242,230,231,230,245,275,293,301,305,342,343,343,342,343,345,346,320,312,311,312,310,301,295,286,270,261,258,241,230,228,219,201,160,140,103,43,21,13,5,0,16,43,75,92,142,182,184,224,228,232,236,238,245,248,249,252,256,257,262,265,268,272,273,275,278,280,282,280,282,284,283,286,284,288,286,288,290,286,290,292,298,294,298,300,302,315,318,335,332,336,338,336,340,345,342,346,342,342];
        const powers = [240,248,252,280,305,330,335,354,340,235,220,225,245,230,235,290,330,370,350,290,260,240,260,355,405,465,480,490]
        if (this.state.innerWidth < 1006){
            return(
                <div style={{...(this.state.innerWidth > 600 ? {marginBottom: "42px"}:{})}} ref={this.refWorkout}>
                    <div>
                        {/*<p>Relive your most epic road rides with our Recollections feature and see how your virtual self stacks up against the real you!</p>*/}
                        {/*<Image className="img-fluid" width="100%" style={{paddingTop: 48, paddingBottom: 48}} src={workout1}/>*/}
                        <div className="container align-items-center" style={{marginTop: "24px", marginBottom: "16px"}}>
                            <p className="title" style={{fontSize: "38px"}}>VIRTUAL POWER METER</p>
                            <p>Don't have a power meter yet or has it run out of juice during your ride? Here's a solution! </p>
                        </div>
                        {/*<div className="blue-background" style={{paddingTop: "16px",
                            paddingBottom: "16px"}}>
                            <VPMWidget powers={powers}/>
                        </div>*/}
                        <div className={this.state.innerWidth > 600 ? "container" : ""}>
                            <div style={{backgroundColor: "black", position: "relative", zIndex: "999", ...(this.state.innerWidth > 600 ? {borderRadius: "30px", overflow: "hidden"} : {})}}>
                                <video ref={this.videoRef} fluid autoPlay loop muted solid playsInline width="100%" style={{
                                    maxHeight: "360px",
                                    objectFit: "cover", padding: "0px", margin: "0px", overflow: "hidden", opacity: "0.8"}}>
                                    <source src={vpmMovie} type="video/mp4"/>
                                </video>
                                <div className="container align-items-center" style={{backgroundColor: "black", paddingTop: "16px", paddingBottom: "16px", marginTop: "0px"}}>
                                    <p className="light-color">Virtual Power Meter estimates your power output in real-time based various factors, such as your current speed, acceleration, road inclination (grade), weight of a system (rider + bike), wind speed and direction, and many others.</p>
                                </div>
                                <div style={{height: "361px",
                                    position: "absolute",
                                    top: "0px",
                                    width: "100%",
                                    background: "linear-gradient(to bottom, rgba(100,0,0,0.0) 60%, rgba(0,0,0,1.0) 100%)"}}>
                                </div>
                                <div style={{paddingTop: "24px",
                                    position: "absolute",
                                    top: "290px",
                                    width: "100%",
                                    paddingBottom: "0px",
                                    textAlign: "center"}}>
                                    <div style={{width: "180px", backgroundColor: "white", margin: "auto", borderRadius: "45px"}}>
                                        <VPMWidget value={this.state.value}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return(
                <div style={{marginBottom: "32px"}}>
                    <div className="section-workout-upper text-body overflow-hidden"  ref={this.refWorkout}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-5">
                                    {/*<p>current time: {Math.round(this.state.currentTime * 100) / 100}</p>
                                    <p>excess perc: {Math.round(this.state.excessPercentage * 100) / 100}</p>
                                    <p>previous index: {this.state.previousIndex}</p>*/}
                                    <div style={{position: "relative"}}>
                                        <video ref={this.videoRef} fluid autoPlay loop muted solid playsInline onEnded={this.handleTimeUpdate} width="100%" style={{
                                            maxHeight: "420px",
                                            objectFit: "cover",
                                            marginBottom: "0px",
                                            marginTop: "-1px",
                                            borderRadius: "30px",
                                            opacity: "90%"}}>
                                            <source src={vpmMovie} type="video/mp4"/>
                                        </video>
                                        <div style={{height: "420px",
                                            position: "absolute",
                                            top: "0px",
                                            width: "100%",
                                            borderRadius: "30px",
                                            background: "linear-gradient(to bottom, rgba(100,0,0,0.0) 60%, rgba(0,0,0,1.0) 100%)"}}>
                                        </div>
                                        <div style={{paddingTop: "24px",
                                            position: "absolute",
                                            bottom: "32px",
                                            width: "100%",
                                            paddingBottom: "0px",
                                            textAlign: "center"}}>
                                            <div style={{width: "180px", backgroundColor: "white", margin: "auto", borderRadius: "45px"}}>
                                                <VPMWidget value={this.state.value}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <p className="title">VIRTUAL POWER METER</p>
                                    <p>Don't have a power meter yet or has it run out of juice during your ride? Here's a solution! </p>
                                    <p>Virtual Power Meter estimates your power output in real-time based various factors, such as your current speed, acceleration, road inclination (grade), weight of a system (rider + bike), wind speed and direction, and many others.</p>
                                    <p>The algorithm estimates aerodynamic drag coefficient based on your body weight and height as well as bike type and assumes you are taking more aerodynamic position on your bike as your speed increases. </p>
                                    {/*<p>Relive your most epic road rides with our Recollections feature and see how your virtual self stacks up against the real you!</p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default VPMSection;